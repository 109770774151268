import React, {useState, useEffect} from 'react';

import Layout from './components/layout';
// ** import styles and fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'd3a9e83c-5955-43a4-b7b6-4c6d1c92d0c2',
    clientToken: 'pubf0910c81411a6f02af8fc49c6d85ff4f',
    site: 'datadoghq.com',
    service:'grays-partner-form',
    env:'production',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
});
    
datadogRum.startSessionReplayRecording();



function PartnerExpressForm() {

datadogRum.startSessionReplayRecording();    
  
  return (
    <Layout />
  );
}


export default PartnerExpressForm;
