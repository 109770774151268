import React, {ReactElement, FC} from "react";
import logo from '../picklesLogo.webp';

const Header:FC<any> = ():ReactElement => {
 return(
   <>
    <div className="flex justify-center w-full h-20">
         <div className="flex w-11/12 justify-between border-b-2 border-solid border-gray-100 pb-2">
            <div className="my-2 items-start">
               <p className="text-xs text-left" style={{color: "gray", fontSize: "8px"}}>Powered By</p>
               <img style={{height: "35px", width: "115px", marginTop:"4px"}} alt="CarClarity Logo" src="https://widgets.carclarity.com.au/img/CarClarity.2d0241f0.svg"/>
               {/* <img style={{height: "40px", marginTop:"14px"}} alt="Grays Logo" src='https://res3.grays.com/handlers/imagehandler.ashx?t=cr&id=78043&s=d&index=0&ct=svg'/> */}
            </div>
         
            <div className="my-auto float-right mr-5">
               <img style={{height: "40px", marginTop:"14px"}} alt="Grays Logo" src='https://res3.grays.com/handlers/imagehandler.ashx?t=cr&id=78043&s=d&index=0&ct=svg'/>
               {/* <img style={{height: "29px", width: "104px"}} alt="CarClarity Logo" src="https://widgets.carclarity.com.au/img/CarClarity.2d0241f0.svg"/> */}
            </div>
         </div>
   </div>
    </>
 )
}

export default Header